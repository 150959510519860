import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Q9CustomEncoder, Q9ParamsInterface, Q9UserService} from '@q9elements/ui-core';
import * as _ from 'lodash';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../../../environments/environment';
import {IGroupAccess, IGroupAccessShort} from '../../../_shared/models/group.interface';
import {RequestEditRightsService} from '../dialogs/request-edit-rights/data-access';
import {InvitePayloadInterface, MAP_TYPE_ENUM, SelectedUserInterface} from '../model/map-models';

@Injectable({
  providedIn: 'root'
})
export class MapsDaoService implements RequestEditRightsService {
  apiUrl: string;

  constructor(private http: HttpClient, private q9UserService: Q9UserService) {
    this.apiUrl = environment.API_ENDPOINT;
  }

  getList(type: string): (params: Q9ParamsInterface) => Observable<any> {
    return (params: Q9ParamsInterface) => {
      const Params = this.composeRequestParams({...params, type});

      return this.http.get<any>(
        `${this.apiUrl}/organization/${this.q9UserService.getTeamId()}/map`,
        {
          params: Params
        }
      );
    };
  }

  getMap(mapId): Observable<any> {
    return this.http.get(`${this.apiUrl}/map/${mapId}`);
  }

  update(mapId: string, data, mapType = MAP_TYPE_ENUM.UPN) {
    const params = new HttpParams().set('type', mapType);

    return this.http.put(`${this.apiUrl}/map/${mapId}`, data, {params, responseType: 'text'});
  }

  toggleAuthorisationMod(mapId: string, isAuthorisationEnabled: boolean) {
    return this.http.put(`${this.apiUrl}/map/${mapId}/authorisation`, {isAuthorisationEnabled});
  }

  private composeRequestParams(params: Q9ParamsInterface): HttpParams {
    // Initialize Params Object
    let Params = new HttpParams({encoder: new Q9CustomEncoder()});
    // Assigning parameters
    _.forIn(params, function (value, key) {
      Params = Params.append(key, `${value}`);
    });
    return Params;
  }

  hardDelete(mapId: string, mapType: MAP_TYPE_ENUM): Observable<any> {
    const params = new HttpParams().set('type', mapType);

    return this.http.delete(`${this.apiUrl}/map/${mapId}`, {params, responseType: 'text'});
  }

  mapUsers(row: any, params: {skip: number; limit: number; sort?: string; search?: string}) {
    const Params = this.composeRequestParams(params);
    return this.http.get<any>(`${this.apiUrl}/map/${row.id}/user`, {params: Params}).pipe(
      map(response => {
        const users = _.map(response.users, (perm: any) => {
          perm.canCopy = perm.canCopy === 1;
          perm.canGrantAccess = perm.canGrantAccess === 1;
          perm.canChangeFingerprint = perm.canChangeFingerprint === 1;
          return perm;
        });
        return {
          owner: response.mapOwner,
          data: users,
          total: response.count,
          row: row
        };
      }),
      catchError(this.handleError)
    );
  }

  getMapEnterprisePermissions(mapId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/map/${mapId}/enterprise/permissions`);
  }

  getAvailableMapVersions(mapId: string) {
    const queryParam = new HttpParams().set('versionStatesOnly', 'true');
    return this.http.get(`${this.apiUrl}/diagram/${mapId}/versions`, {params: queryParam});
  }

  exportMap(mapId: string) {
    return this.http.get(`${this.apiUrl}/map/${mapId}/export`);
  }

  requestEditRights(mapId: string, message: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/map/${mapId}/request-rights`, {message});
  }

  exportLocalUrls(row: {id: string; team: {_id: string}}) {
    return this.http.post(
      `${this.apiUrl}/organizations/${row.team._id}/urllibrary/export`,
      {id: row.id, type: 'map'},
      {responseType: 'text'}
    );
  }

  updateMapRights(mapId: string, userId: string, newRole: {role: string}): Observable<any> {
    const url = `${this.apiUrl}/map/${mapId}/user/${userId}`;
    return this.http.put(url, {user: userId, map: mapId, role: newRole.role});
  }

  removeUserFromMap(mapId: string, userId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}/map/${mapId}/user/${userId}`);
  }

  mapOwnerCandidates(mapId: string, search: string) {
    const params = this.composeRequestParams({search});
    return this.http.get(`${this.apiUrl}/map/${mapId}/candidates/owners`, {params});
  }

  inviteToMapCandidates(mapId: string, search: string) {
    const params = this.composeRequestParams({search});
    return this.http.get(`${this.apiUrl}/map/${mapId}/candidates/access`, {params});
  }

  changeOwner(mapId: string, author: string) {
    return this.http.put(`${this.apiUrl}/map/${mapId}/author`, {author});
  }

  copyMap(
    mapId: string,
    newMap: {
      mapName: string;
      team: string;
      dataTableRecords: boolean;
      comments: boolean;
      linkedChangeRecords: boolean;
    }
  ): Observable<any> {
    return this.http.post(`${this.apiUrl}/map/${mapId}`, newMap).pipe(
      map((result: any) => {
        result.mapCopy.orgName = result.mapCopy.organization.name;
        return result;
      })
    );
  }

  canCopy(mapId: string, userId: string, canCopy: boolean): Observable<any> {
    const url = `${this.apiUrl}/map/${mapId}/user/${userId}`;
    return this.http.put(url, {user: userId, map: mapId, canCopy});
  }

  canAddNotes(mapId: string, userId: string, canAddNotes: boolean): Observable<any> {
    const url = `${this.apiUrl}/map/${mapId}/user/${userId}`;
    return this.http.put(url, {user: userId, map: mapId, canAddNotes});
  }

  canGrantAccess(mapId: string, userId: string, canGrantAccess: boolean): Observable<any> {
    const url = `${this.apiUrl}/map/${mapId}/user/${userId}`;
    return this.http.put(url, {
      user: userId,
      map: mapId,
      canGrantAccess
    });
  }

  inviteIntoMap(
    mapId: string,
    selectedUsers: SelectedUserInterface[],
    data: InvitePayloadInterface
  ) {
    return this.http.post(`${this.apiUrl}/map/${mapId}/user`, {
      map: mapId,
      canCopy: data.canCopy,
      canGrantAccess: data.canGrantAccess,
      message: data.message,
      selectedUsers
    });
  }

  sendImportedMapOnServer(uploadUrl: string, key: string, team: string, mapType: MAP_TYPE_ENUM) {
    return this.http
      .post(uploadUrl, {team, zipfileId: key, mapType})
      .pipe(catchError(this.handleError));
  }

  groupsOnMap(mapId: string): Observable<{groups: IGroupAccess[]}> {
    return this.http.get<{groups: IGroupAccess[]}>(`${this.apiUrl}/map/${mapId}/group`);
  }

  updateMapGroups(mapId: string, body): Observable<IGroupAccess[]> {
    return this.http.put<IGroupAccess[]>(`${this.apiUrl}/map/${mapId}/group`, body);
  }

  addGroupToMap(mapId: string, groups: IGroupAccessShort[]): Observable<IGroupAccess[]> {
    return this.http.post<IGroupAccess[]>(`${this.apiUrl}/map/${mapId}/group`, {groups});
  }

  getExternalGptConfig(): Observable<{enabled: boolean; validCredentials: boolean}> {
    return this.http.get<{enabled: boolean; validCredentials: boolean}>(
      `${this.apiUrl}/enterprises/external-gpt-config`
    );
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(error.error);
  }
}
